import React from 'react'
import styles from '../sass/components/select.module.scss'
import camelCaseClassName from '../utils/covert-sass-styles-to-camelCase'

export default ({
  size = 'normal',
  name,
  id,
  onChange,
  options,
  placeholder,
  ...rest
}) => (
  <select
    name={name}
    id={id}
    {...rest}
    className={`${styles.select} ${styles[camelCaseClassName(size, 'select')]}`}
    onChange={onChange && (event => onChange(event))}
    defaultValue={placeholder && ''}
  >
    {placeholder && (
      <option value="" disabled hidden>
        {placeholder}
      </option>
    )}
    {options.map((op, key) => (
      <option key={key} value={op.value}>
        {op.label}
      </option>
    ))}
  </select>
)
